import { tuiIsHTMLElement } from '@taiga-ui/cdk/utils/dom';
import { svgNodeFilter } from '@taiga-ui/cdk/constants';

/**
 * Returns current active element, including shadow dom
 *
 * @return element or null
 */
function tuiGetNativeFocused({ activeElement }) {
    if (!(activeElement === null || activeElement === void 0 ? void 0 : activeElement.shadowRoot)) {
        return activeElement;
    }
    let element = activeElement.shadowRoot.activeElement;
    while (element === null || element === void 0 ? void 0 : element.shadowRoot) {
        element = element.shadowRoot.activeElement;
    }
    return element;
}

/**
 * Finds and blurs current active element, including shadow DOM
 */
function tuiBlurNativeFocused(doc) {
    const activeElement = tuiGetNativeFocused(doc);
    if (tuiIsHTMLElement(activeElement)) {
        activeElement.blur();
    }
}

/**
 * Checks for signs that element can be focused with keyboard. tabIndex above 0 is ignored to
 * only target natural focus order. Not checking the possibility of an element to
 * be focused, for example element can have display: none applied to it or any other
 * circumstances could prevent actual focus.
 */
function tuiIsNativeKeyboardFocusable(element) {
    if (element.hasAttribute('disabled') || element.getAttribute('tabIndex') === '-1') {
        return false;
    }
    if ((tuiIsHTMLElement(element) && element.isContentEditable) ||
        element.getAttribute('tabIndex') === '0') {
        return true;
    }
    switch (element.tagName) {
        case 'BUTTON':
        case 'SELECT':
        case 'TEXTAREA':
            return true;
        case 'VIDEO':
        case 'AUDIO':
            return element.hasAttribute('controls');
        case 'INPUT':
            return element.getAttribute('type') !== 'hidden';
        case 'A':
        case 'LINK':
            return element.hasAttribute('href');
        default:
            return false;
    }
}

function tuiIsNativeMouseFocusable(element) {
    return (!element.hasAttribute('disabled') &&
        (element.getAttribute('tabIndex') === '-1' ||
            tuiIsNativeKeyboardFocusable(element)));
}

/**
 * @description:
 * Finds the closest element that can be focused with a keyboard or mouse in theory
 */
function tuiGetClosestFocusable({ initial, root, previous = false, keyboard = true, }) {
    if (!root.ownerDocument) {
        return null;
    }
    const check = keyboard ? tuiIsNativeKeyboardFocusable : tuiIsNativeMouseFocusable;
    const treeWalker = root.ownerDocument.createTreeWalker(root, NodeFilter.SHOW_ELEMENT, svgNodeFilter);
    treeWalker.currentNode = initial;
    while (previous ? treeWalker.previousNode() : treeWalker.nextNode()) {
        if (tuiIsHTMLElement(treeWalker.currentNode)) {
            initial = treeWalker.currentNode;
        }
        if (tuiIsHTMLElement(initial) && check(initial)) {
            return initial;
        }
    }
    return null;
}

/**
 * Checks if element is focused.
 *
 * Could return true even after blur since element remains focused if you switch away from a browser tab.
 *
 * @param node or null (as a common return value of DOM nodes walking)
 * @return true if focused
 */
function tuiIsNativeFocused(node) {
    return (!!(node === null || node === void 0 ? void 0 : node.ownerDocument) &&
        tuiGetNativeFocused(node.ownerDocument) === node &&
        node.ownerDocument.hasFocus());
}

/**
 * Checks if focused element is within given element.
 *
 * @param node
 * @return true if focused node is contained within element
 */
function tuiIsNativeFocusedIn(node) {
    // !node.contains - check for IE11
    if (!node.ownerDocument || !node.contains) {
        return false;
    }
    const nativeFocused = tuiGetNativeFocused(node.ownerDocument);
    return (nativeFocused !== null &&
        node.contains(nativeFocused) &&
        node.ownerDocument.hasFocus());
}

/**
 * Utility method for moving focus in a list of elements
 *
 * @param currentIndex currently focused index
 * @param elements array of focusable elements
 * @param step a step to move focus by, typically -1 or 1
 */
function tuiMoveFocus(currentIndex, elements, step) {
    currentIndex += step;
    while (currentIndex >= 0 && currentIndex < elements.length) {
        elements[currentIndex].focus();
        if (tuiIsNativeFocused(elements[currentIndex])) {
            return;
        }
        currentIndex += step;
    }
}

/**
 * Focuses or blurs element with mouse action imitation (to spoof {@link TuiFocusVisibleService})
 *
 * @param element
 * @param focused desired focused state
 * @param preventScroll optionally prevent native browser scroll after focus
 */
function tuiSetNativeMouseFocused(element, focused = true, preventScroll = false) {
    if (!element.ownerDocument) {
        return;
    }
    if (typeof Event === 'function') {
        element.dispatchEvent(new Event('mousedown', { bubbles: true, cancelable: true }));
    }
    else {
        const event = element.ownerDocument.createEvent('Event');
        event.initEvent('mousedown', true, true);
        element.dispatchEvent(event);
    }
    if (focused) {
        element.focus({ preventScroll });
    }
    else {
        element.blur();
    }
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiBlurNativeFocused, tuiGetClosestFocusable, tuiGetNativeFocused, tuiIsNativeFocused, tuiIsNativeFocusedIn, tuiIsNativeKeyboardFocusable, tuiIsNativeMouseFocusable, tuiMoveFocus, tuiSetNativeMouseFocused };

