import * as i0 from '@angular/core';
import { InjectionToken, inject, ElementRef, NgZone, Injectable, Inject, Directive, Attribute, NgModule } from '@angular/core';
import * as i1 from 'rxjs';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { WINDOW } from '@ng-web-apis/common';
const RESIZE_OPTION_BOX_DEFAULT = 'content-box';
const RESIZE_OPTION_BOX = new InjectionToken('Box model to observe changes', {
  providedIn: 'root',
  factory: () => RESIZE_OPTION_BOX_DEFAULT
});
const RESIZE_OBSERVER_SUPPORT = new InjectionToken('Resize Observer API support', {
  providedIn: 'root',
  factory: () => !!inject(WINDOW).ResizeObserver
});
class ResizeObserverService extends Observable {
  constructor({
    nativeElement
  }, ngZone, support, box) {
    let observer;
    super(subscriber => {
      if (!support) {
        subscriber.error('ResizeObserver is not supported in your browser');
        return;
      }
      observer = new ResizeObserver(entries => {
        ngZone.run(() => {
          subscriber.next(entries);
        });
      });
      observer.observe(nativeElement, {
        box
      });
      return () => {
        observer.disconnect();
      };
    });
    return this.pipe(share());
  }
}
ResizeObserverService.ɵfac = function ResizeObserverService_Factory(t) {
  return new (t || ResizeObserverService)(i0.ɵɵinject(ElementRef), i0.ɵɵinject(NgZone), i0.ɵɵinject(RESIZE_OBSERVER_SUPPORT), i0.ɵɵinject(RESIZE_OPTION_BOX));
};
ResizeObserverService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ResizeObserverService,
  factory: ResizeObserverService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResizeObserverService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [RESIZE_OBSERVER_SUPPORT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [RESIZE_OPTION_BOX]
      }]
    }];
  }, null);
})();

/**
 * @deprecated View Engine legacy
 * TODO: use arrow function for `useFactory` and delete this exported function in future major release
 */
// TODO switch to Attribute once https://github.com/angular/angular/issues/36479 is fixed
function boxExtractor({
  nativeElement
}) {
  const attribute = nativeElement.getAttribute('waResizeBox');
  return boxFactory(attribute);
}
/**
 * @deprecated View Engine legacy
 * TODO: use arrow function for `useFactory` and delete this exported function in future major release
 */
function boxFactory(box) {
  return box || RESIZE_OPTION_BOX_DEFAULT;
}
class ResizeObserverDirective {
  constructor(waResizeObserver, _box) {
    this.waResizeObserver = waResizeObserver;
  }
}
ResizeObserverDirective.ɵfac = function ResizeObserverDirective_Factory(t) {
  return new (t || ResizeObserverDirective)(i0.ɵɵdirectiveInject(ResizeObserverService), i0.ɵɵinjectAttribute('waResizeBox'));
};
ResizeObserverDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ResizeObserverDirective,
  selectors: [["", "waResizeObserver", ""]],
  outputs: {
    waResizeObserver: "waResizeObserver"
  },
  features: [i0.ɵɵProvidersFeature([ResizeObserverService, {
    provide: RESIZE_OPTION_BOX,
    deps: [ElementRef],
    useFactory: boxExtractor
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResizeObserverDirective, [{
    type: Directive,
    args: [{
      selector: '[waResizeObserver]',
      outputs: ['waResizeObserver'],
      providers: [ResizeObserverService, {
        provide: RESIZE_OPTION_BOX,
        deps: [ElementRef],
        useFactory: boxExtractor
      }]
    }]
  }], function () {
    return [{
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [ResizeObserverService]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Attribute,
        args: ['waResizeBox']
      }]
    }];
  }, null);
})();
class ResizeObserverModule {}
ResizeObserverModule.ɵfac = function ResizeObserverModule_Factory(t) {
  return new (t || ResizeObserverModule)();
};
ResizeObserverModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ResizeObserverModule
});
ResizeObserverModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResizeObserverModule, [{
    type: NgModule,
    args: [{
      declarations: [ResizeObserverDirective],
      exports: [ResizeObserverDirective]
    }]
  }], null, null);
})();

/**
 * Public API Surface of @ng-web-apis/resize-observer
 */

/**
 * Generated bundle index. Do not edit.
 */

export { RESIZE_OBSERVER_SUPPORT, RESIZE_OPTION_BOX, RESIZE_OPTION_BOX_DEFAULT, ResizeObserverDirective, ResizeObserverModule, ResizeObserverService, boxExtractor, boxFactory };
